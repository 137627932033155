import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'createShoppingList',
    'shoppingListWholesalers',
    'form',
    'ndc',
    'addProductErrorMessage',
    'productListingTable',
    'quantity',
    'productId',
    'shippingPreferenceGround',
    'shippingPreferenceSecondDay',
    'shippingPreferenceThreeDay',
    'shippingPreferenceOvernight',
    'excludeNonNabpSellers',
    'excludeShortDateItems',
    'shortDateItemsOnly',
    'createShoppingListButton',
    'wholesalersInfo',
    'addToCart',
    'checkout'
  ]

  static values = {
    shoppingListId: Number
  }

  // Target callbacks

  ndcTargetConnected(element) {
    this.bindAutoComplete(element)
  }

  formTargetConnected(element) {
    $(element).validate() // Form validations
  }

  quantityTargetConnected() {
    this.refreshProductListing()
  }

  quantityTargetDisconnected() {
    this.refreshProductListing()
  }

  wholesalersInfoTargetConnected() {
    this.createShoppingListTarget.classList.add('hide')
    this.shoppingListWholesalersTarget.classList.remove('hide')
  }

  // Methods

  handleSubmit(e) {
    e.preventDefault()

    this.refreshProductListing()
    if (this.quantityTargets.length == 0 || !$('#shopping_list_form').valid())
      return

    const stimulusThis = this

    $.ajax({
      type: 'PUT',
      cache: false,
      url: `/pharmacy/shopping_lists/${stimulusThis.shoppingListIdValue}`,
      dataType: 'script',
      data: $(this.formTarget).serializeArray(),
      headers: {
        Authorization: 'Web'
      },
      beforeSend() {
        stimulusThis.createShoppingListButtonTarget.disabled = true
      },
      error(jqXHR, textStatus, errorThrown) {
        window.alertify.error(jqXHR.responseJSON.message)
      },
      complete() {
        stimulusThis.createShoppingListButtonTarget.disabled = false
      }
    })
  }

  handleBack(e) {
    e.preventDefault()

    this.shoppingListWholesalersTarget.classList.add('hide')
    this.createShoppingListTarget.classList.remove('hide')
  }

  handleAddToCart(e) {
    e.preventDefault()

    const actionType = e.target.dataset.actionType
    const { supplierId, productId } = e.params

    const stimulusThis = this

    $.ajax({
      type: 'POST',
      cache: false,
      url: `/pharmacy/shopping_lists/${stimulusThis.shoppingListIdValue}/add_to_cart/${supplierId}`,
      dataType: 'json',
      data: {
        product_id: productId
      },
      headers: {
        Authorization: 'Web'
      },
      beforeSend() {
        stimulusThis.addToCartTargets.forEach((t) =>
          t.disabled = true
        )
        stimulusThis.checkoutTargets.forEach((t) =>
          t.disabled = true
        )
      },
      success(data) {
        $('#createShoppingListModal').modal('hide')
        if (actionType === 'checkout') {
          window.location.href = `/checkout?id=${data.cart_order.id}`
        }
      },
      error(jqXHR, textStatus, errorThrown) {
        window.alertify.error(jqXHR.responseJSON.message)
      },
      complete() {
        stimulusThis.addToCartTargets.forEach((t) =>
          t.disabled = false
        )
        stimulusThis.checkoutTargets.forEach((t) =>
          t.disabled = false
        )
      }
    })
  }

  shippingPreferenceGroundChanged(e) {
    if (e.target.checked) {
      this.shippingPreferenceSecondDayTarget.checked = false
      this.shippingPreferenceThreeDayTarget.checked = false
      this.shippingPreferenceOvernightTarget.checked = false
    }
  }

  shippingPreferenceSecondDayChanged(e) {
    if (e.target.checked) {
      this.shippingPreferenceGroundTarget.checked = false
      this.shippingPreferenceThreeDayTarget.checked = false
      this.shippingPreferenceOvernightTarget.checked = false
    }
  }

  shippingPreferenceThreeDayChanged(e) {
    if (e.target.checked) {
      this.shippingPreferenceGroundTarget.checked = false
      this.shippingPreferenceSecondDayTarget.checked = false
      this.shippingPreferenceOvernightTarget.checked = false
    }
  }

  shippingPreferenceOvernightChanged(e) {
    if (e.target.checked) {
      this.shippingPreferenceGroundTarget.checked = false
      this.shippingPreferenceSecondDayTarget.checked = false
      this.shippingPreferenceThreeDayTarget.checked = false
    }
  }

  excludeShortDateItemsChanged(e) {
    if (e.target.checked) {
      this.shortDateItemsOnlyTarget.disabled = true
      this.shortDateItemsOnlyTarget.checked = false
    } else {
      this.shortDateItemsOnlyTarget.disabled = false
    }
  }

  shortDateItemsOnlyChanged(e) {
    if (e.target.checked) {
      this.excludeShortDateItemsTarget.disabled = true
      this.excludeShortDateItemsTarget.checked = false
    } else {
      this.excludeShortDateItemsTarget.disabled = false
    }
  }

  refreshProductListing() {
    if (this.quantityTargets.length == 0) {
      this.addProductErrorMessageTarget.classList.remove('hide')
      this.productListingTableTarget.classList.add('hide')
    } else {
      this.addProductErrorMessageTarget.classList.add('hide')
      this.productListingTableTarget.classList.remove('hide')
    }
  }

  removeFromList(e) {
    e.target.parentElement.parentElement.parentElement.previousElementSibling.children[0].value =
      '1'
    e.target.parentElement.parentElement.parentElement.remove()
  }

  bindAutoComplete(element) {
    const stimulusThis = this
    $(element).autocomplete({
      open: function () {
        setTimeout(function () {
          $('.ui-autocomplete').css('z-index', 1051)
        }, 0)
      },
      source: function (request, response) {
        $.ajax({
          type: 'GET',
          cache: false,
          dataType: 'json',
          url: '/api/v1/products/request/autosuggest',
          data: {
            keyword: $(element).val()
          },
          headers: {
            Authorization: 'Web'
          },
          beforeSend() {
            $('.ndc-search-loading').show()
          },
          success(data) {
            $('.ndc-search-loading').hide()

            response(
              $.map(data, function (item) {
                return {
                  label: `${item.ndc} - ${item.name} - ${item.package_size}`,
                  value: '',
                  item_id: item.id,
                  ndc: item.ndc,
                  form: item.form,
                  gpi: item.gpi,
                  ddi: item.ddi,
                  strength: item.strength,
                  package_qty: item.package_qty,
                  package_size: item.package_size,
                  manufacturer: item.manufacturer
                }
              })
            )
          },
          error(jqXHR, textStatus, errorThrown) {
            console.error('something went wrong')
            $('.ndc-search-loading').hide()
          }
        })
      },
      select: function (event, ui) {
        const productIds = stimulusThis.productIdTargets.map((t) =>
          Number(t.value)
        )

        if (productIds.includes(ui.item.item_id)) {
          setTimeout(function () {
            $('.shopping-list-item-search').val('')
          }, 100)
          alertify.error(I18n.t('promotion_bundles.product_already_added'))
        } else {
          $.get(
            `/pharmacy/shopping_lists/${stimulusThis.shoppingListIdValue}/add_to_list/${ui.item.item_id}`
          )
        }
      },
      minLength: 3
    })
  }
}
